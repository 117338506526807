import React from 'react';
import { string, shape } from 'prop-types';
import Section from '../section';
import StyledLabelFormated from '../styled-label/styled-label-formated';

const namespace = 'label-card';

const LabelCard = ({ type, label, action }) => (
  <Section type={type} className={namespace}>
    {label && (
      <StyledLabelFormated
        as="span"
        dataModel={{ ...label, accessibilityText: label?.alt_text }}
        className={`${namespace}__text`}
      />
    )}
    {action && (
      <StyledLabelFormated
        as="a"
        dataModel={action.label}
        {...action.label}
        href={action.target}
      />)}
  </Section>
);


LabelCard.propTypes = {
  type: string,
  label: shape({
    text: string,
    alt_text: string,
    styles: {
      color: string,
      font_size: string,
      font_family: string,
    },
  }),
  action: {
    label: {
      color: string,
      font_family: string,
      font_size: string,
      text: string,
    },
    target: string,
  },
};

export default LabelCard;
